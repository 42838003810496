<template>
  <div id="add-event-image">
    <v-card class="overflow-hidden">
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title class="text-white">{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitForm"
          >
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required field
                  </p>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Organization Type
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="OrganizationType"
                    :rules="OrganizationTypeRules"
                    :items="OrganizationTypeOptions"
                    :loading="OrganizationTypeOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6><span class="text-danger">*</span> Designation Type</h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="DesignationType"
                    :rules="DesignationTypeRules"
                    :items="DesignationTypeOptions"
                    :loading="DesignationTypeOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      Area
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="AreaCode"
                    :rules="AreaCodeRules"
                    :items="AreaCodeOptions"
                    :loading="AreaCodeOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      Region
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="RegionCode"
                    :rules="RegionCodeRules"
                    :items="RegionCodeOptions"
                    :loading="RegionCodeOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6><span class="text-danger">*</span> Portfolio</h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="PortfolioId"
                    :rules="PortfolioIdRules"
                    :items="PortfolioIdOptions"
                    :loading="PortfolioIdOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6> Event Category <small>(optional)</small></h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="EventCategoryId"
                    :rules="EventCategoryIdRules"
                    :items="EventCategoryIdOptions"
                    :loading="EventCategoryIdOptionsLoading"
                    dense
                    clearable
                    outlined
                    multiple
                    chips
                    deletable-chips
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6><span class="text-danger">*</span> Designation Name</h6>
                  </label>
                  <v-text-field
                    v-model="DesignationName"
                    :rules="DesignationNameRules"
                    placeholder="Designation Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Designation Prefix
                    </h6>
                  </label>
                  <v-text-field
                    v-model="DesignationPrefix"
                    :rules="DesignationPrefixRules"
                    placeholder="Designation Prefix"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Designation Max Count
                    </h6>
                  </label>
                  <v-text-field
                    v-model="DesignationMaxCount"
                    :rules="DesignationMaxCountRules"
                    outlined
                    dense
                    required
                    min="0"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6>Designation Status</h6>
                  </label>
                  <v-switch
                    inset
                    v-model="DesignationStatus"
                    :label="`${DesignationStatus ? 'Active' : 'Inactive'}`"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    AddRecordPrompt: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      OrganizationTypeRules: [(v) => !!v || "Organization Type is required"],
      OrganizationType: "",
      OrganizationTypeOptions: [],
      OrganizationTypeOptionsLoading: false,

      DesignationTypeRules: [(v) => !!v || "Designation Type is required"],
      DesignationType: "",
      DesignationTypeOptions: [],
      DesignationTypeOptionsLoading: false,

      AreaCodeRules: [],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      RegionCodeRules: [],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      PortfolioIdRules: [(v) => !!v || "Portfolio is required"],
      PortfolioId: 1,
      PortfolioIdOptions: [],
      PortfolioIdOptionsLoading: false,

      EventCategoryIdRules: [
        // (v) => !!v || "Event Category is required"
      ],
      EventCategoryId: "",
      EventCategoryIdOptions: [],
      EventCategoryIdOptionsLoading: false,

      DesignationName: "",
      DesignationPrefix: "Jc",
      DesignationMaxCount: 1,
      DesignationStatus: 1,

      DesignationNameRules: [(v) => !!v || "Designation Name is required"],
      DesignationPrefixRules: [(v) => !!v || "Designation Prefix is required"],
      DesignationMaxCountRules: [
        (v) => v >= 0 || "Designation Max Count is greater then or equal to 0.",
      ],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        this.getOrganizationTypeOptions();
        this.getDesignationTypeOptions();
        this.getAreaCodeOptions();
        this.getRegionCodeOptions();
        this.getPortfolioIdOptions();
        this.getEventCategoryIdOptions();
      }
    },
    OrganizationTypeOptions: function () {
      console.log("watch OrganizationTypeOptions");
      this.OrganizationTypeOptionsLoading = false;
    },
    EventCategoryIdOptions: function () {
      console.log("watch EventCategoryIdOptions");
      this.EventCategoryIdOptionsLoading = false;
    },
    AreaCodeOptions: function () {
      console.log("watch AreaCodeOptions");
      this.AreaCodeOptionsLoading = false;
    },
    RegionCodeOptions: function () {
      console.log("watch RegionCodeOptions");
      this.RegionCodeOptionsLoading = false;
    },
    PortfolioIdOptions: function () {
      console.log("watch PortfolioIdOptions");
      this.PortfolioIdOptionsLoading = false;
    },
    PortfolioId: function () {
      console.log("watch PortfolioId");
      this.EventCategoryId = "";
      this.getEventCategoryIdOptions();
    },
    EventCategoryIdOptions: function () {
      console.log("watch EventCategoryIdOptions");
      this.EventCategoryIdOptionsLoading = false;
    },
    DesignationTypeOptions: function () {
      console.log("watch DesignationTypeOptions");
      this.DesignationTypeOptionsLoading = false;
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "designation",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.ResultFlag);
    },
    getOrganizationTypeOptions() {
      console.log("getOrganizationTypeOptions called");
      this.OrganizationTypeOptionsLoading = true;
      this.OrganizationType = "";
      var selectbox1_source = "OrganizationType";
      var selectbox1_destination = "OrganizationTypeOptions";
      var selectbox1_url = "api/organization-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDesignationTypeOptions() {
      console.log("getDesignationTypeOptions called");
      this.DesignationTypeOptionsLoading = true;
      this.DesignationType = "";
      var selectbox1_source = "DesignationType";
      var selectbox1_destination = "DesignationTypeOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        CategoryMainName: "designation_type",
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getAreaCodeOptions() {
      console.log("getAreaCodeOptions called");
      this.AreaCodeOptionsLoading = true;
      this.AreaCode = "";
      var selectbox1_source = "AreaCode";
      var selectbox1_destination = "AreaCodeOptions";
      var selectbox1_url = "api/area/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");
      this.RegionCodeOptionsLoading = true;
      this.RegionCode = "";
      var selectbox1_source = "RegionCode";
      var selectbox1_destination = "RegionCodeOptions";
      var selectbox1_url = "api/region/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getPortfolioIdOptions() {
      console.log("getPortfolioIdOptions called");
      var selectbox1_source = "PortfolioId";
      var selectbox1_destination = "PortfolioIdOptions";
      var selectbox1_url = "api/portfolio/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getEventCategoryIdOptions() {
      console.log("getEventCategoryIdOptions called");
      var PortfolioId = this.PortfolioId;
      console.log("PortfolioId=" + PortfolioId);
      if (PortfolioId != "") {
        this.EventCategoryIdOptionsLoading = true;
        var selectbox1_source = "EventCategoryId";
        var selectbox1_destination = "EventCategoryIdOptions";
        var selectbox1_url = "api/jci-event-type/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          Portfolio: PortfolioId,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (PortfolioId == "") {
          message += "Portfolio should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var upload = {
          UserInterface: 1,
          DesignationName: this.DesignationName,
          OrganizationType: this.OrganizationType,
          DesignationType: this.DesignationType,
          Area: this.AreaCode,
          Region: this.RegionCode,
          Portfolio: this.PortfolioId,
          EventCategoryId: [],
          DesignationPrefix: this.DesignationPrefix,
          DesignationMaxCount: this.DesignationMaxCount,
          DesignationStatus: this.DesignationStatus,
        };
        for (var i = 0; i < this.EventCategoryId.length; i++) {
          upload.EventCategoryId.push(this.EventCategoryId[i]);
        }

        console.log({ upload });

        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/designation/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#add-event-image {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>